<template>
    <div class="company_employee-search">
        <PageTitle
            icon="fa fa-clone"
            main="Registro"
            sub="Funcionário/Documentação"
        />
        <b-row>
              <b-col md="6" sm="6">
                   <b-form-group
                        label="Selecione o Contrato:"
                        label-for="company_search"
                    >
                        <b-form-select
                            id="company_search"
                            size="sm"
                            :options="companys"
                            @input="loadcompany"
                            v-model=" company_id"
                            :disabled="form_loading"
                        />
                    </b-form-group>
              </b-col>
               <b-col md="6" sm="6">
                     <b-button  class="mr-2 mt-4" variant="primary" v-show="form_loading" disabled>
                            <b-spinner small type="grow"></b-spinner>
                            Atualizando dados...
                    </b-button>
               </b-col>
                   
        </b-row>
        <hr>
        <b-form>
            <b-row>
                <b-col md="5" sm="12">
                    <b-form-group
                        label="Empreendimento:"
                        label-for="company-name_build_company"
                    >
                        <b-form-input
                            id="company-name_build_company"
                            type="text"
                            v-model="company.name_build_company"
                            required
                            size="sm"
                            :readonly="true"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                    <b-form-group
                        label="Código:"
                        label-for="company-code_company"
                    >
                        <b-form-input
                            id="company-code_company"
                            type="text"
                            v-model="company.code_company"
                            required
                            size="sm"
                            :readonly="true"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                    <b-form-group
                        label="Data Início:"
                        label-for="company-start_date_company"
                    >
                        <b-form-input
                            id="company-start_date_company"
                            type="date"
                            v-model="company.start_date_company"
                            :readonly="true"
                            size="sm"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                    <b-form-group
                        label="Data Termino:"
                        label-for="company-end_date_company"
                    >
                        <b-form-input
                            id="company-end_date_company"
                            type="date"
                            v-model="company.end_date_company"
                            :readonly="true"
                            size="sm"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="5" sm="12">
                    <b-form-group
                        label="Empreiteiro:"
                        label-for="company-name_company"
                    >
                        <b-form-input
                            id="company-name_company"
                            type="text"
                            v-model="company.name_company"
                            required
                            size="sm"
                            :readonly="true"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12">
                    <b-form-group
                        label="Nº Contrato:"
                        label-for="company-contact_number_company"
                    >
                        <b-form-input
                            id="company-contact_number_company"
                            type="text"
                            v-model="company.contact_number_company"
                            required
                            size="sm"
                            :readonly="true"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
        <hr />
        <b-row v-show="!form_loading">
            <b-col md="3" sm="12">
                <div class="form-group">
                    <input
                        type="text"
                        size="lg"
                        icon="search"
                        v-model="search"
                        placeholder="Pesquise pelo nome do funcionário"
                        class="form-control"
                    />
                </div>
            </b-col>
            <b-col md="2" sm="12">
                <b-button
                    size="lg"
                    variant="outline-success"
                    @click=";[(company_employee = {}), openModalEmployee()]"
                    title="Adicionar Funcionário"
                    v-show="!this.company.blocked_company"
                    v-if="company.code_company > 0"
                    class="mb-1 mr-2 fa fa-plus"
                > Adicionar </b-button>
            </b-col>
        </b-row>
        <b-row v-show="!form_loading">
             <b-col md="6" sm="12">
                   <b-form-group
                        label="Status do Funcionário:"
                        label-for="status_employee_id"
                    >
                        <b-form-select
                            id="status_employee_id"
                            size="sm"
                            :options="status_employee_searh"
                            @input="loadCompanyEmployees"
                            v-model="status_employee_id"
                        />
                    </b-form-group>
              </b-col>
        </b-row>
        <b-row v-show="!form_loading">
            <b-col md="12" sm="12">
                <transition name="slide" type="animation" appear>
                    <b-table
                         :busy="isBusyEmployee"
                        class="table-responsive"
                        hover
                        head-variant="ligth"
                        :items="filteredList"
                        :fields="fields"
                        :tbody-tr-class="rowClass"
                    >
                        <template v-slot:table-busy>
                        <div class="text-center text-dark my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Carregando...</strong>
                        </div>
                    </template>
                        <template v-slot:cell(accesses)="data">
                            <b-button
                                title="Solictar aprovação Documento(s)"
                                size="sm"
                                variant="light"
                                 v-if="data.item.staus_id_employee ==0 && !data.item.return_preview_code > 0"
                                @click="
                                    ;[
                                        showMsgBoxAllow(data.item)
                                    ]
                                "
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-exchange"></i>
                                Solicitar Aprovação
                            </b-button>
                            <b-button
                                title="Visualizar Documento(s)"
                                size="sm"
                                variant="light"
                                 v-if="data.item.staus_id_employee !=3 && data.item.staus_id_employee !=4 "
                                @click="
                                    ;[
                                        loadCompanyEmployee(data.item),
                                        loadCompanyEmployeeFilesModal()
                                    ]
                                "
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-drivers-license-o"></i>
                                Visualizar Documento(s)
                            </b-button>
                            <b-button
                                title="Adicionar Documento"
                                size="sm"
                                variant="light"
                                 v-if="data.item.staus_id_employee !=3 && data.item.staus_id_employee !=4 && !data.item.return_preview_code > 0"
                                @click="
                                    ;[
                                        loadCompanyEmployee(data.item),
                                        openModalEmployeeFile(),
                                        file_doc_show = true,
                                        modalFileShow = true,
                                        file_upload_show = false,
                                        (company_employee_file = {})
                                    ]
                                "
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-plus"></i>
                                Adicionar Documento
                            </b-button>
                            <b-button
                                title="Desativar Funcionário"
                                size="sm"
                                variant="light"
                                 v-if="data.item.staus_id_employee ==3"
                                @click="
                                    ;[
                                        showMsgBoxBlocked(data.item),
                                    ]
                                "
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-unlock-alt"></i>
                                Desativar
                            </b-button>
                            <b-button
                                title="Cancelar/Excluir"
                                size="sm"
                                variant="light"
                                 v-if="data.item.staus_id_employee ===0 ||data.item.staus_id_employee ===1 ||data.item.staus_id_employee ===2 "
                                @click="
                                    ;[
                                        showMsgBoxCancel(data.item),
                                    ]
                                "
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-ban"></i>
                                Cancelar/Excluir
                            </b-button>
                        </template>
                    </b-table>
                 
                </transition>
                <transition name="slide" type="animation" appear>
                    <!--b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        size="sm"
                        aria-controls="my-table"
                    ></b-pagination!-->
                </transition>
            </b-col>
        </b-row>
        <b-modal
            id="modalEmployee"
            ref="modal"
            title-tag="h6"
            size="lg"
            title="Registro Funcionário"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            @ok="save"
        >
            <b-row>
                <b-col md="3" sm="12">
                    <b-form-group
                        label="CPF:"
                        label-for="company_employee-cpf_employee"
                    >
                        <b-form-input
                            v-mask="['###.###.###-##']"
                            id="company_employee-cpf_employee"
                            type="text"
                            ref="focusThis"
                            v-model="company_employee.cpf_employee"
                            size="sm"
                            placeholder="CPF..."
                        />
                    </b-form-group>
                </b-col>
                <b-col md="7" sm="12">
                    <b-form-group
                        label="Nome:"
                        label-for="company_employee-name_employee"
                    >
                        <b-form-input
                            id="company_employee-name_employee"
                            type="text"
                            v-model="company_employee.name_employee"
                            size="sm"
                            placeholder="Nome do Funcionário.."
                        />
                    </b-form-group>
                </b-col>
                <b-col>
                        <b-form-checkbox
                            id="company_employee-responsible"
                            v-model="company_employee.responsible"
                              size="sm"
                        >
                            Sócio (Responsável)
                        </b-form-checkbox>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal
            id="modalEmployeeFileSearch"
            title-tag="h6"
            size="xl"
            centered
            ok-only
            title="Documento(s)"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
            <b-table
                class="table-responsive"
                hover
                head-variant="light"
                :items="company_employee_files"
                :fields="fieldsFile"
            >
                <template v-slot:cell(actions)="data">
                    <b-button
                        title="Editar registro"
                        size="sm"
                        variant="light"
                        @click="
                            ;[
                                loadCompanyEmployeeFile(data.item),
                                  file_upload_show = false,
                                openModalEmployeeFileEdit()
                            ]
                        "
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-edit"></i>
                    </b-button>
    
                    <b-button
                        title="Visualizar"
                        size="sm"
                        variant="light"
                        v-if="data.item.storage_file_id > 0"
                        @click="
                            ;[
                                loadCompanyEmployeeFile(data.item),
                                openModalEmployeeFilePDF()
                            ]
                        "
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-file-pdf-o"></i>
                        Visualizar
                    </b-button>
                    <!--b-button
                        title="Confirmar Envio"
                        size="sm"
                           v-if="data.item.storage_file_id > 0"
                        variant="light"
                        @click="checkFileFtp(data.item)"
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-check"></i>
                    </b-button!-->
                </template>
            </b-table>
        </b-modal>
        <b-modal
             v-model="modalFileShow"
            id="modalEmployeeFile"
            title-tag="h6"
            centered
            ok-only
            size="xl"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
    
        >
        <RegisterEmployeeDoc :company_employee="company_employee"/>
            <!--b-row>
                
                <b-col md="7" sm="12">
                    <b-form-group
                        label="Tipo de Documento:"
                        label-for="company_employee_file-type_document_id_file"
                    >
                        <b-form-select
                            id="company_employee_file-type_document_id_file"
                            size="sm"
                            :options="type_documents"
                            @input="file_doc_show = false"
                            :disabled="type_doc_show"
                            v-model="
                                company_employee_file.type_document_id_file
                            "
                        />
                    </b-form-group>
                </b-col>
                <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
         <b-modal
             v-model="modalFileShowEdit"
            id="modalFileEdit"
            title-tag="h6"
            centered
            size="lg"
            title="Editar Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
    
        >
            <b-row>
                <b-col md="12" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShowEdit=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template-->
        </b-modal>
        <b-modal
            id="modalEmployeeFilePDF"
            ref="modal"
            title-tag="h6"
            centered
            size="lg"
            ok-only
            title="Visualização do Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
            <div>
                <pdf :src="company_employee_file.url_file"></pdf>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import RegisterEmployeeDoc from './RegisterEmployeeDoc.vue'
import { mask } from 'vue-the-mask'
import pdf from 'vue-pdf'
var moment = require('moment-timezone')

export default {
    name: 'EmployeeSearch',
    directives: { mask },
    components: { PageTitle, pdf,RegisterEmployeeDoc },
    data: function() {
        return {
            mode: 'insert',
            search: '',
            company: {},
             companys: [],
            company_employee: {},
            company_employees: [],
            company_employee_file: {},
            company_employee_files: [],
             isBusyEmployee: false,
            type_documents: [],
            status_employee_id: null,
            file: null,
            form_loading:false,
            type_doc_show: false,
            file_doc_show: true,
            file_upload_show: false,
            id_file_type_document: null,
            company_id:null,
            perPage: 5,
            currentPage: 1,
            showTable: false,
            modalFileShow: false,
            modalFileShowEdit:false,
            headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
             status_employee_searh: [
                        { text: 'TODOS', value: null },
                         { text: 'ABERTO', value: 0 },
                        { text: 'EM ANDAMENTO', value: 1 },
                        { text: 'REJEITADO', value: 2 },
                        { text: 'CONCLUÍDA', value: 3 },
                        { text: 'CANCELADO', value: 4 }

                    ],
            fields: [
                {
                    key: 'name_employee',
                    label: 'Nome Funcionário',
                    sortable: false
                },
                {
                    key: 'cpf_employee',
                    label: 'CPF',
                    sortable: false
                },
                {
                    key: 'blocked',
                    label: 'Desativado',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                {
                    key: 'return_preview_code',
                    label: 'Nº Solicitação',
                    sortable: false
                },
                  {
                    key: 'staus_id_employee',
                    label: 'Status',
                    sortable: false,
                      formatter: value => this.status_employee(value)
                },
              //  { key: 'actions', label: 'Ações', variant: 'light' },
                { key: 'accesses', label: 'Documento(s)'}
            ],
            fieldsFile: [
                {
                    key: 'name_type_document',
                    label: 'Documento',
                    sortable: true
                },
                {
                    key: 'name_file',
                    label: 'Arquivo',
                    sortable: false
                },
                {
                    key: 'status_type_document_file',
                    label: 'Status',
                    sortable: false,
                    formatter: value => this.status_file(value)
                },
                {
                    key: 'justify_comments',
                    label: 'Justificativa',
                    sortable: false
                },
                { key: 'actions', label: 'Ações', variant: 'light' }
            ]
        }
    },
    computed: {
        filteredList() {
            return this.company_employees.filter(company_employee => {
                return company_employee.name_employee
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
            })
        },
        compan() {
            return this.$store.state.compan
        },
        icon() {
            return this.showTable ? 'fa-retweet' : 'fa-search'
        },
        rows() {
            return this.company_employees.length
        },
        user() {
            return this.$store.state.user
        }
    },
    methods: {
         toggleBusy() {
            this.isBusyEmployee = true
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status_description === 'Concluida') return 'table-success'
            if (item.status_description === 'cancelado') return 'table-danger'
            if (item.status_description === 'docRegeitado') return 'table-warning'
         },
        rowClassDocument(item, type) {
            if (!item || type !== 'row') return
            if (item.status_type_document_file === 1) return 'table-success'
            if (item.status_type_document_file === 2) return 'table-warning'
         },
        status_file(value) {
            if (value === 0) {
                return 'PENDENTE'
            } else if (value === 1) {
                return 'AUTORIZADO'
            } else if (value === 2) {
                return 'REJEITADO'
            } else if (value === 3) {
                return 'EM ANALISE'
            }
        },
           status_employee(value) {
            if (value === 0) {
                return 'ABERTO'
            } else if (value === 1) {
                return 'EM ANDAMENTO'
            } else if (value === 2) {
                return 'REJEITADO'
            } else if (value === 3) {
                return 'CONCLUÍDA'
            }else if (value === 4) {
                return 'CANCELADO'
            }
        },
         showMsgBoxAllow(item) {
            this.boxCancel = ''
            this.$bvModal
                .msgBoxConfirm('Deseja solicitar aprovação ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                        this.toggleBusy()
                        await this.allowFiles(item)
                    }
                })
        },
           showMsgBoxBlocked(item) {
            this.boxCancel = ''
            this.$bvModal
                .msgBoxConfirm('Deseja desativar o funcionário ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                        this.toggleBusy()
                        this.blockedCompanyEmployee(item)
                    }
                })
        },
        showMsgBoxCancel(item) {
            this.boxCancel = ''
            this.$bvModal
                .msgBoxConfirm('Deseja Cancelar/Excluir o Registro ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                        await this.canceledCompanyEmployee(item)
                    }
                })
        },
        async loadCompanyEmployees() {
            const url = `${baseApiUrl}/companyemployees/${this.company.id}/user/?status_employee_id=${this.status_employee_id}`
            await axios(url).then(res => {
                this.company_employees = res.data
                this.isBusyEmployee = false
                
            })
              .finally( () => {
                      this.isBusyEmployee = false
                })

        },
      async  loadCompanyEmployeeFiles() {
            const url = `${baseApiUrl}/companyemployeefiles/${this.company_employee.id}/employee`
         await   axios(url).then(res => {
                this.company_employee_files = res.data
            })
        },
          async  loadCompanyEmployeeFilesModal() {
            const url = `${baseApiUrl}/companyemployeefiles/${this.company_employee.id}/employee`
         await   axios(url).then(res => {
                this.company_employee_files = res.data
            })   
            .finally( () => {
                     this.openModalEmployeeFileSearch()
                })
        },
        reset() {
            this.loadCompanyEmployees()
            this.company_employee = {}
        },
        async save() {
            this.company_employee.company_id_employee = this.company.id
            const method = this.company_employee.id ? 'put' : 'post'
            const id = this.company_employee.id
                ? `/${this.company_employee.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companyemployees${id}`,
                this.company_employee
            )
                .then(async () => {
                    await this.returnCodeFolderEmployee()
                    //this.reset()
                })
                .catch(showError)
        },
        async saveFile() {
            this.company_employee_file.company_employee_id = this.company_employee.id
            const method = this.company_employee_file.id ? 'put' : 'post'
            const id = this.company_employee_file.id
                ? `/${this.company_employee_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companyemployeefiles${id}`,
                this.company_employee_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    //this.loadCompanyEmployeeFiles()
                })
                .finally(async () => {
                    await this.getLastFileTypeDocument()
                })
                .catch(showError)
        },
        remove(item) {
            const id = item.id
            axios
                .delete(`${baseApiUrl}/companyemployees/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyEmployees()
                })
                .catch(showError)
        },

        async loadcompany() {
            const url = `${baseApiUrl}/companys/${this.company_id}`
            await axios
                .get(url)
                .then(res => {
                    this.company = {
                        ...res.data,
                        start_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD'),
                        end_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD')
                    }
                })
                  .then(async () => {
                        this.toggleBusy()
                    await this.loadCompanyEmployees()
                })
                .finally(async () => {
                    await this.loadCompanyEmployees()
                })
        },
        loadCompanyEmployee(item) {
            this.company_employee = { ...item }
        },
        loadCompanyEmployeeFile(item) {
            this.company_employee_file = { ...item }
        },
       async  loadTypeDocuments() {
            const url = `${baseApiUrl}/typedocumentsemployeefiles/${this.company_employee.id}`
         await   axios.get(url).then(res => {
                this.type_documents = res.data.map(type_document => {
                    return {
                        value: type_document.id,
                        text: type_document.name_type_document
                    }
                })
            })
        },
        openModalEmployee() {
            this.$bvModal.show('modalEmployee')
        },
        async openModalEmployeeFile() {
         await   this.loadTypeDocuments()
           this.type_doc_show =false
            this.$bvModal.show('modalEmployeeFile')
           
        },
        openModalEmployeeFileEdit(){
            this.file_doc_show = false
            this.$bvModal.show('modalFileEdit')
        },
        async openModalEmployeeFileSearch() {
         await   this.loadCompanyEmployeeFiles()
            this.$bvModal.show('modalEmployeeFileSearch')
        },
        openModalEmployeeFilePDF() {
            this.$bvModal.show('modalEmployeeFilePDF')
        },
         closeModalEmployeeFilePDF() {
            this.$bvModal.hide('modalEmployeeFilePDF')
        },
        async saveImagem() {

            this.file_upload_show = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('company_id_storage', this.company.id)
            formData.append('company_employee_id_storage', this.company_employee.id)
            formData.append('type_document_id_storage', this.company_employee_file.type_document_id_file)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( async () => {
                    this.$toasted.global.defaultSuccess()
                     await  this.getLastStorageFile()
                    this.type_doc_show =true
                })
                .finally(async ()=>{
                    if(this.company_employee_file.storage_file_id > 0){
                      await  this.saveFile()
                    }else{
                          this.$toasted.global.defaultWarning({
                                msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                                });
                    }
                })
                .catch(showError)
            

         
        },
        async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesbylast/?name_file=${this.file.name}&company_id_storage=${this.company.id}&company_employee_id_storage=${this.company_employee.id}&type_document_id_storage=${this.company_employee_file.type_document_id_file}`
            await axios(url).then(
                res =>
                    (this.company_employee_file = {
                        storage_file_id: res.data.id,
                        name_file: res.data.name_file,
                        path_file: res.data.path_file,
                        id: this.company_employee_file.id,
                        type_document_id_file: this.company_employee_file
                            .type_document_id_file
                    })
            )
        },
        async getLastFileTypeDocument() {
            const url = `${baseApiUrl}/companyemployeefilesbylast/?company_employee_id=${this.company_employee_file.company_employee_id}&type_document_id_file=${this.company_employee_file.type_document_id_file}`
            await axios(url).then(
                res =>
                    (
                        this.checkFileFtpById(res.data.id)
                    )
            )
        },
        async checkFileFtp(item) {
            this.company_employee_file = { ...item }
            
            if(item.storage_file_id > 0){
                  await axios['put'](
                `${baseApiUrl}/companyemployeefilesftp/${this.company_employee_file.id}`,
                this.company_employee_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                   
                 
                })
                .finally( () => {
                        this.loadCompanyEmployeeFiles()
                      
                })
                .catch(showError)
            }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }
          
        },
        async checkFileFtpById(id) {

                  await axios['put'](
                `${baseApiUrl}/companyemployeefilesftpbyid/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.modalFileShow = false
                     this.modalFileShowEdit = false
                })
                .finally( () => {
                        this.loadCompanyEmployeeFiles()
                           
                })
                .catch(showError)
          
          
        },
          async returnCodeFolderEmployee() {
            const method = 'post'
            const id = this.company.id
                ? `/${this.company.id}`
                : ''

            this.company_employee.parent_document_id = this.company.parent_document_id

            await axios[method](
                `${baseApiUrl}/returncompanyemployees${id}`,
                this.company_employee
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyEmployees()
                    this.company_employee = {}
                })
                .catch(showError)
        },
    async   allowFiles(item){
                  const method = 'post'
            const id = item.id
                ? `/${item.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companyemployeefilescodereturn${id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyEmployees()
                })
                .catch(showError)
                this.isBusyEmployee = false
        },
          async loadCompanys() {

             this.form_loading = true

            const url = `${baseApiUrl}/companybycompanys/?email=${this.user.email}&register_number=${this.user.register_number}&user_id=${this.user.id}`
            await axios
                .get(url)
                .then(res => {
                    this.companys = res.data.map(company => {
                        return {
                            ...company,
                            value: company.id,
                            text:
                                company.name_build_company +
                                '-' +
                                company.contact_number_company
                        }
                    })
                })
                .finally( () => {
                      this.form_loading = false
                      
                    })
     },
    async loadCompanyFiles() {

            this.form_loading = true

            const url = `${baseApiUrl}/companyemployeefilefluig/${this.user.id}`
            await axios.get(url).then(res => {
                this.files = res.data
            })
                .finally( () => {
                      this.form_loading = false
                      
                    })
    },
    blockedCompanyEmployee(item){

          const method = 'put'
            const id = item.id
                ? `/${item.id}`
                : ''
             axios[method](
                `${baseApiUrl}/blockedcompanyemployees${id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyEmployees()
                })
                .catch(showError)
                this.isBusyEmployee = false

    },
    async canceledCompanyEmployee(item){

          const method = 'post'
            const id = item.id
                ? `/${item.id}`
                : ''
             axios[method](
                `${baseApiUrl}/canceledcompanyemployees${id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyEmployees()
                })
                .catch(showError)
                this.isBusyEmployee = false

    }

    

    },
    
    async mounted() {

        await this.loadCompanys()    
        await this.loadCompanyFiles()
      
              
    }
}
</script>

<style>
.company_employee-search {
    font-size: 0.75rem;
}

.company_employee-search button {
    font-size: 0.75rem;
}

.company_employee-search select {
    font-size: 0.75rem;
}

.company_employee-search input {
    font-size: 0.75rem;
}

#modalEmployee {
    font-size: 0.75rem;
}

#modalEmployee button {
    font-size: 0.75rem;
}

#modalEmployee input {
    font-size: 0.75rem;
}

#modalEmployee label {
    font-size: 0.75rem;
}


#modalEmployeeFile {
    font-size: 0.75rem;
}

#modalEmployeeFile button {
    font-size: 0.75rem;
}

#modalEmployeeFile input {
    font-size: 0.75rem;
}

#modalEmployeeFile select {
    font-size: 0.75rem;
}

#modalEmployeeFileSearch {
    font-size: 0.75rem;
}

#modalEmployeeFileSearch button {
    font-size: 0.75rem;
}

#modalEmployeeFileSearch table {
    font-size: 0.75rem;
}

#modalEmployeeFilePDF {
    font-size: 0.75rem;
}

#modalEmployeeFilePDF button {
    font-size: 0.75rem;
}

#modalFileEdit {
     font-size: 0.75rem;
}

#modalFileEdit button {
     font-size: 0.75rem;
}


@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
