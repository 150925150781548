<template>
    <div class="company_employee-search">
        <PageTitle
            icon="fa fa-clone"
            main="Registro"
            sub="Documentação da Empresa"
        />
        <b-form>
            <b-row>
                <b-col md="5" sm="12">
                    <b-form-group
                        label="Razão Social:"
                        label-for="users_complement-social_reason"
                    >
                        <b-form-input
                            id="users_complement-social_reason"
                            type="text"
                            v-model='users_complement.social_reason'
                            size="sm"
                            :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12">
                    <b-form-group
                        label="Nome Fantasia:"
                        label-for="users_complement-fantasy_name"
                    >
                        <b-form-input
                            id="users_complement-fantasy_name"
                            type="text"
                            v-model="users_complement.fantasy_name"
                            size="sm"
                            :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                   <b-form-group
                        label="Contribuinte ISS:"
                        label-for="users_complement-taxpayer_iss"
                    >
                        <b-form-select
                            id="users_complement-taxpayer_iss"
                            size="sm"
                            :options="question_yes_no"
                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                            v-model=" users_complement.taxpayer_iss"
                        />
                    </b-form-group>
              </b-col>
               <b-col md="2" sm="12">
                   <b-form-group
                        label="Optante Simples:"
                        label-for="users_complement-opting_simple"
                    >
                        <b-form-select
                            id="users_complement-opting_simple"
                            size="sm"
                            :options="question_yes_no"
                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                            v-model=" users_complement.opting_simple"
                        />
                    </b-form-group>
              </b-col>
            </b-row>
            <b-row>
                 <b-col md="3" sm="12">
                    <b-form-group
                        label="Inscrição Estadual:"
                        label-for="users_complement-state_registration"
                    >
                        <b-form-input
                            id="users_complement-state_registration"
                            type="text"
                            v-model="users_complement.state_registration"
                            required
                            size="sm"
                            :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                        />
                    </b-form-group>
                </b-col>
                 <b-col md="3" sm="12">
                    <b-form-group
                        label="Inscrição Municipal:"
                        label-for="users_complement-municipal_registration"
                    >
                        <b-form-input
                            id="users_complement-municipal_registration"
                            type="text"
                            v-model="users_complement.municipal_registration"
                            required
                            size="sm"
                            :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                        />
                    </b-form-group>
                </b-col>
                 <b-col md="2" sm="12">
                   <b-form-group
                        label="Regime ISS:"
                        label-for="users_complement-regime_iss_id"
                    >
                        <b-form-select
                            id="users_complement-regime_iss_id"
                            size="sm"
                            :options="regime_iss"
                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                            v-model=" users_complement.regime_iss_id"
                        />
                    </b-form-group>
              </b-col>
                <b-col md="2" sm="12">
                   <b-form-group
                        label="Porte da Empresa:"
                        label-for="users_complement-company_size_id"
                    >
                        <b-form-select
                            id="users_complement-company_size_id"
                            size="sm"
                            :options="company_sizes"
                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                            v-model=" users_complement.company_size_id"
                        />
                    </b-form-group>
              </b-col>
                <b-col md="2" sm="12">
                   <b-form-group
                        label="Desoneração da Folha Pagto:"
                        label-for="users_complement-payroll_exemption"
                    >
                        <b-form-select
                            id="users_complement-payroll_exemption"
                            size="sm"
                            :options="question_yes_no"
                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                            v-model=" users_complement.payroll_exemption"
                        />
                    </b-form-group>
              </b-col>
            </b-row>
            <b-row>
                  <b-col md="3" sm="12">
                    <b-form-group
                        label="Telefone Principal:"
                        label-for="users_complement-main_phone"
                    >
                        <b-form-input
                            id="users_complement-main_phone"
                            type="text"
                            v-model="users_complement.main_phone"
                            required
                            size="sm"
                            :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12">
                    <b-form-group
                        label="Telefone:"
                        label-for="users_complement-phone"
                    >
                        <b-form-input
                            id="users_complement-phone"
                            type="text"
                            v-model="users_complement.phone"
                            required
                            size="sm"
                            :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                        />
                    </b-form-group>
                </b-col>
                 <b-col md="3" sm="12">
                    <b-form-group
                        label="CNPJ:"
                        label-for="users_complement-cnpj"
                    >
                        <b-form-input
                            id="users_complement-cnpj"
                            type="text"
                            v-model="users_complement.cnpj"
                            required
                            size="sm"
                            :readonly="true"
                        />
                    </b-form-group>
                </b-col>
                  <b-col md="2" sm="12">
                    <b-form-group
                        label="Nº Solicitação:"
                        label-for="users_complement-return_preview_code"
                    >
                        <b-form-input
                            id="users_complement-return_preview_code"
                            type="text"
                            v-model="users_complement.return_preview_code"
                            required
                            size="sm"
                            :readonly="true"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
             <b-card no-body bg-variant="light"  text-variant="dark">
                    <b-tabs card  align="center">
                        <b-tab active>
                                 <template v-slot:title>
                                     <i class="fa fa-address-book-o"></i> <strong>Endereço</strong>
                                 </template>
                                 <b-form>
                                     <b-row>
                                           <b-col md="2" sm="6">
                                                <b-form-group
                                                    label="CEP:"
                                                    label-for="users_address-zipcode"
                                                >
                                                    <b-form-input
                                                        id="users_address-zipcode"
                                                        type="text"
                                                        v-model="users_address.zipcode"
                                                        required
                                                        size="sm"
                                                        :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                              <b-col md="2" sm="6">
                                                <b-form-group
                                                        label="Tipo:"
                                                        label-for="users_address-street_type"
                                                    >
                                                        <b-form-select
                                                            id="users_address-street_type"
                                                            size="sm"
                                                            :options="street_types"
                                                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                            v-model=" users_address.street_type"
                                                        />
                                                    </b-form-group>
                                            </b-col>
                                             <b-col md="5" sm="6">
                                                <b-form-group
                                                    label="Endereço:"
                                                    label-for="users_address-address"
                                                >
                                                    <b-form-input
                                                        id="users_address-address"
                                                        type="text"
                                                        v-model="users_address.address"
                                                        required
                                                        size="sm"
                                                        :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                              <b-col md="1" sm="6">
                                                <b-form-group
                                                    label="Nº:"
                                                    label-for="users_address-number_address"
                                                >
                                                    <b-form-input
                                                        id="users_address-number_address"
                                                        type="text"
                                                        v-model="users_address.number_address"
                                                        required
                                                        size="sm"
                                                        :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                             <b-col md="2" sm="6">
                                                <b-form-group
                                                    label="Completo:"
                                                    label-for="users_address-complement_address"
                                                >
                                                    <b-form-input
                                                        id="users_address-complement_address"
                                                        type="text"
                                                        v-model="users_address.complement_address"
                                                        size="sm"
                                                        :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                     </b-row>
                                     <b-row>
                                           <b-col md="2" sm="6">
                                                <b-form-group
                                                        label="Tipo de Bairro:"
                                                        label-for="users_address-district_type"
                                                    >
                                                        <b-form-select
                                                            id="users_address-district_type"
                                                            size="sm"
                                                            :options="district_types"
                                                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                            v-model=" users_address.district_type"
                                                        />
                                                    </b-form-group>
                                            </b-col>
                                              <b-col md="2" sm="6">
                                                <b-form-group
                                                    label="Bairro:"
                                                    label-for="users_address-district"
                                                >
                                                    <b-form-input
                                                        id="users_address-district"
                                                        type="text"
                                                        v-model="users_address.district"
                                                        size="sm"
                                                        :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                              <b-col md="2" sm="6">
                                                <b-form-group
                                                        label="Pais:"
                                                        label-for="users_address-country_code"
                                                    >
                                                        <b-form-select
                                                            id="users_address-country_code"
                                                            size="sm"
                                                            :options="countrys"
                                                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                            v-model=" users_address.country_code"
                                                        />
                                                    </b-form-group>
                                            </b-col>
                                               <b-col md="2" sm="6">
                                                <b-form-group
                                                        label="Estado:"
                                                        label-for="users_address-state_code"
                                                    >
                                                        <b-form-select
                                                            id="users_address-state_code"
                                                            size="sm"
                                                            :options="states"
                                                            @input="loadCities"
                                                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                            v-model=" users_address.state_code"
                                                        />
                                                    </b-form-group>
                                            </b-col>
                                             <b-col md="4" sm="6">
                                                <b-form-group
                                                        label="Cidade:"
                                                        label-for="users_address-city_code"
                                                    >
                                                        <b-form-select
                                                            id="users_address-city_code"
                                                            size="sm"
                                                            :options="cities"
                                                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                            v-model=" users_address.city_code"
                                                        />
                                                    </b-form-group>
                                            </b-col>
                                     </b-row>
                                 </b-form>
                        </b-tab>
                          <b-tab >
                                 <template v-slot:title>
                                     <i class="fa fa-bank"></i> <strong>Dados bancários</strong>
                                 </template>
                                 <b-form>
                         
                                     <b-row>
                                          <b-col md="3" sm="6">
                                                <b-form-group
                                                        label="Forma Pagamento"
                                                        label-for="users_bank-payment_method_id"
                                                    >
                                                        <b-form-select
                                                            id="users_bank-payment_method_id"
                                                            size="sm"
                                                            :options="paymentmethods"
                                                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                            v-model=" users_bank.payment_method_id"
                                                        />
                                                    </b-form-group>
                                            </b-col>
                                           <b-col md="4" sm="12">
                                            <b-form-group
                                                label="Favorecido:"
                                                label-for="users_bank-favored"
                                            >
                                                <b-form-input
                                                    id="users_bank-favored"
                                                    type="text"
                                                    v-model="users_bank.favored"
                                                    size="sm"
                                                    :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                />
                                                </b-form-group>
                                             </b-col>
                                            <b-col md="2" sm="12">
                                            <b-form-group
                                                label="CNPJ/CPF Favorecido:"
                                                label-for="users_bank-cpf_cnpj_favored"
                                            >
                                                <b-form-input
                                                    id="users_bank-cpf_cnpj_favored"
                                                    type="text"
                                                    v-model="users_bank.cpf_cnpj_favored"
                                                    size="sm"
                                                    :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                />
                                                </b-form-group>
                                             </b-col>
                                               <b-col md="3" sm="6">
                                                <b-form-group
                                                        label="Tipo de Conta"
                                                        label-for="users_bank-type_account_id"
                                                    >
                                                        <b-form-select
                                                            id="users_bank-type_account_id"
                                                            size="sm"
                                                            :options="typeaccounts"
                                                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                            v-model=" users_bank.type_account_id"
                                                        />
                                                    </b-form-group>
                                            </b-col>
                                     </b-row>
                                     <b-row>
                                             <b-col md="3" sm="6">
                                                <b-form-group
                                                        label="Banco:"
                                                        label-for="users_bank-bank_code"
                                                    >
                                                        <b-form-select
                                                            id="users_bank-bank_code"
                                                            size="sm"
                                                            :options="banks"
                                                            :disabled="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                            v-model=" users_bank.bank_code"
                                                        />
                                                    </b-form-group>
                                            </b-col>
                                            <b-col md="2" sm="6">
                                            <b-form-group
                                                label="Agência:"
                                                label-for="users_bank-agency"
                                            >
                                                <b-form-input
                                                    id="users_bank-agency"
                                                    type="text"
                                                    v-model="users_bank.agency"
                                                    size="sm"
                                                    :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                />
                                                </b-form-group>
                                             </b-col>
                                            <b-col md="1" sm="6">
                                            <b-form-group
                                                label="Digito:"
                                                label-for="users_bank-digital_agency"
                                            >
                                                <b-form-input
                                                    id="users_bank-digital_agency"
                                                    type="text"
                                                    v-model="users_bank.digital_agency"
                                                    size="sm"
                                                    :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                />
                                                </b-form-group>
                                             </b-col>
                                            <b-col md="3" sm="6">
                                                <b-form-group
                                                    label="Nome da Agência:"
                                                    label-for="users_bank-agency_name"
                                                >
                                                    <b-form-input
                                                        id="users_bank-agency_name"
                                                        type="text"
                                                        v-model="users_bank.agency_name"
                                                        size="sm"
                                                        :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                    />
                                                    </b-form-group>
                                             </b-col>
                                             <b-col md="2" sm="6">
                                                <b-form-group
                                                    label="Conta Corrente:"
                                                    label-for="users_bank-checking_account"
                                                >
                                                    <b-form-input
                                                        id="users_bank-checking_account"
                                                        type="text"
                                                        v-model="users_bank.checking_account"
                                                        size="sm"
                                                        :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                    />
                                                    </b-form-group>
                                             </b-col>
                                             <b-col md="1" sm="6">
                                                <b-form-group
                                                    label="Digito:"
                                                    label-for="users_bank-digital_checking_account"
                                                >
                                                    <b-form-input
                                                        id="users_bank-digital_checking_account"
                                                        type="text"
                                                        v-model="users_bank.digital_checking_account"
                                                        size="sm"
                                                        :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                    />
                                                    </b-form-group>
                                             </b-col>
                                     </b-row>
                                 </b-form>
                        </b-tab>
                          <b-tab >
                                 <template v-slot:title>
                                     <i class="fa fa-id-card-o"></i> <strong>Dados para Contrato</strong>
                                 </template>
                                 <b-form>
                                     <b-row>
                                          <b-col md="2" sm="12">
                                            <b-form-group 
                                                label="% ISS:" 
                                                label-for="users_tax-iss">
                                            <b-form-input 
                                                id="users_tax-iss" 
                                                v-model="users_tax.iss" 
                                                type="number"
                                                size="sm"
                                                :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                step="0.02"
                                                pattern="([0-9]{1,3}\.)?[0-9]{1,3},[0-9]{2}$"
                                                />
                                            </b-form-group>
                                        </b-col>  
                                        <b-col md="2" sm="12">
                                            <b-form-group 
                                                label="% INSS:" 
                                                label-for="users_tax-inss">
                                            <b-form-input 
                                                id="users_tax-inss" 
                                                v-model="users_tax.inss" 
                                                type="number"
                                                size="sm"
                                                :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                step="0.02"
                                                pattern="([0-9]{1,3}\.)?[0-9]{1,3},[0-9]{2}$"
                                                />
                                            </b-form-group>
                                        </b-col>  
                                              <b-col md="2" sm="12">
                                            <b-form-group 
                                                label="% Mão de Obra:" 
                                                label-for="users_tax-perc_bulid">
                                            <b-form-input 
                                                id="users_tax-perc_bulid" 
                                                v-model="users_tax.perc_bulid" 
                                                type="number"
                                                size="sm"
                                                :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                step="0.02"
                                                pattern="([0-9]{1,3}\.)?[0-9]{1,3},[0-9]{2}$"
                                                />
                                            </b-form-group>
                                        </b-col>  
                                        <b-col md="2" sm="12">
                                            <b-form-group 
                                                label="% Retenção Técnica:" 
                                                label-for="users_tax-perc_ret_tecnical">
                                            <b-form-input 
                                                id="users_tax-perc_ret_tecnical" 
                                                v-model="users_tax.perc_ret_tecnical" 
                                                type="number"
                                                size="sm"
                                                :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                step="0.02"
                                                pattern="([0-9]{1,3}\.)?[0-9]{1,3},[0-9]{2}$"
                                                />
                                            </b-form-group>
                                        </b-col> 
                                         <b-col md="2" sm="12">
                                            <b-form-group 
                                                label="% IRRF:" 
                                                label-for="users_tax-irrf">
                                            <b-form-input 
                                                id="users_tax-irrf" 
                                                v-model="users_tax.irrf" 
                                                type="number"
                                                size="sm"
                                                :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                step="0.02"
                                                pattern="([0-9]{1,3}\.)?[0-9]{1,3},[0-9]{2}$"
                                                />
                                            </b-form-group>
                                        </b-col>  
                                           <b-col md="2" sm="12">
                                            <b-form-group 
                                                label="% Retenção CS:" 
                                                label-for="users_tax-cs">
                                            <b-form-input 
                                                id="users_tax-cs" 
                                                v-model="users_tax.cs" 
                                                type="number"
                                                size="sm"
                                                :readonly="mode === 'remove' || mode === 'read' || mode === 'insert'"
                                                step="0.02"
                                                pattern="([0-9]{1,3}\.)?[0-9]{1,3},[0-9]{2}$"
                                                />
                                            </b-form-group>
                                        </b-col>  
                                     </b-row>
                                 </b-form>
                        </b-tab>
                          <b-tab >
                                 <template v-slot:title>
                                     <i class="fa fa-id-badge"></i> <strong>Responsáveis Legais pela Empresa</strong>
                                 </template>
                                 <b-row>
                                      <b-button  
                                        title="Adicionar Responsável"  
                                        size="lq" 
                                        variant="outline-success" 
                                        class="mb-2 ml-3 fa fa-save"
                                        @click="openModalResponsilble"> Adicionar Responsável
                                    </b-button>
                                 </b-row>
                                    <b-row>
                                        <b-col md="12" sm="12">
                                            <transition name="slide" type="animation" appear>
                                                <b-table
                                                    class="table-responsive"
                                                    hover
                                                    head-variant="ligth"
                                                    :items="users_responsibles"
                                                    :fields="fieldsResponsible"
                                                    :tbody-tr-class="rowClass"
                                                >
                                                    <template v-slot:cell(actions)="data">
                                                        <b-button
                                                            title="Editar"
                                                            size="sm"
                                                            variant="light"
                                                            @click="
                                                                ;[
                                                                    editModalResponsilble(data.item)
                                                                ]
                                                            "
                                                            class="mr-2 mt-2"
                                                           
                                                        >
                                                            <i class="fa fa-edit"></i>
                                                            Editar
                                                        </b-button>
                                                        <b-button
                                                            title="Excluir"
                                                            size="sm"
                                                            variant="light"
                                                            @click="
                                                                ;[
                                                                    showMsgBoxRemoveResponsable(data.item)
                                                                ]
                                                            "
                                                            class="mr-2 mt-2"
                                                            v-if="users_complement.return_preview_code > 0" 
                                                        >
                                                            <i class="fa fa-trash"></i>
                                                            Excluir
                                                        </b-button>
                        
                                                    </template>
                                                </b-table>
                                            
                                            </transition>
                                            <transition name="slide" type="animation" appear>
                                            </transition>
                                        </b-col>
                                    </b-row>
                        </b-tab>
                    </b-tabs>
             </b-card>
        </b-form>
        <hr />
      <b-row>
           <b-col xs="12">
                    <b-button  title="Editar" size="lq" class="mb-2 fa fa-edit" variant="outline-warning" v-show="mode !== 'save'" v-if="users_complement.return_preview_code > 0"
                        @click="alterModo"> Editar 
                    </b-button>
                    <b-button  title="Salvar"  size="lq" variant="outline-primary" class="mb-2 ml-2 fa fa-save" v-if="mode === 'save'"
                        @click="saveComplement"> Salvar
                    </b-button>
                      <b-button  title="Voltar"  size="lq" variant="outline-dark" class="mb-2 ml-2  fa fa-sign-out" v-if="mode === 'save'"
                        @click="reset"> Voltar
                    </b-button>
                     <b-button  title="Solicitar Autorização"  size="lq" variant="outline-dark" class="mb-2 ml-2  fa fa-check-square-o" 
                            v-if="users_complement.return_preview_code > 0"
                                :disabled="allow_document_unique"
                                @click="showMsgBoxAllow"> Solicitar Autorização
                                    <b-spinner small v-show="allow_document_unique"></b-spinner>
                    </b-button>
                    <b-button  title="Solicitar Autorização"  size="lq" variant="outline-dark" class="mb-2 ml-2  fa fa-check-square-o" 
                            v-if="users_complement.return_preview_code == 0 && users_files.length > 0"
                                :disabled="allow_document_unique"
                                @click="showMsgBoxAllowCompl"> Solicitar Autorização
                                    <b-spinner small v-show="allow_document_unique"></b-spinner>
                    </b-button>
                      <b-button class="mb-2 ml-2" variant="primary" disabled  v-show="upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                </b-col>
      </b-row>
        <hr />
      <b-card no-body bg-variant="light"  text-variant="dark" >
           <b-tabs card >
                        <b-tab active>
                                 <template v-slot:title>
                                     <i class="	fa fa-folder-o"></i> <strong>Documento(s)</strong>
                                 </template>
                                 <b-form>
                                     <b-row>
                                          <b-col xs="12">
                                            <b-button  
                                                    title="Documento" 
                                                    size="lq" 
                                                    class="mb-2 fa fa-plus" 
                                                    variant="outline-success" 
                                                    :disabled="upload_show"
                                                    v-if="users_complement.return_preview_code > 0"
                                                @click="openModalResposibleFile"> Documento 
                                            </b-button>
                                              <!--b-button  
                                                    title="Documento Complementar" 
                                                    size="lq" 
                                                    class="mb-2 fa fa-plus" 
                                                    variant="outline-success" 
                                                    :disabled="upload_show"
                                                    v-if="users_complement.return_preview_code == 0"
                                                @click="openModalResposibleFile"> Documento Complementar
                                            </b-button-->
                                             <b-button  
                                                    title="Atualizar" 
                                                    size="lq" 
                                                    class="ml-2 mb-2 fa fa-refresh" 
                                                    variant="outline-dark" 
                                                 @click="loadUserFiles()"> Atualizar
                                            </b-button>
                                        </b-col>
                                     </b-row>
                                     <b-row>
                                        <b-table
                                        class="table-responsive"
                                        hover
                                        head-variant="ligth"
                                        :items="users_files"
                                        :fields="fieldsFile"
                                        >
                                        <template v-slot:cell(actions)="data">
                                        <b-button
                                        title="Visualizar"
                                        size="sm"
                                        variant="light"
                                        v-if="data.item.storage_file_id > 0"
                                        @click="
                                        ;[
                                        loadCompanyEmployeeFile(data.item),
                                        openModalEmployeeFilePDF()
                                        ]
                                        "
                                        class="mr-2 mt-2"
                                        >
                                        <i class="fa fa-file-pdf-o"></i>
                                        Visualizar
                                        </b-button>
                                        </template>
                                        </b-table>
                                     </b-row>
                                 </b-form>
                        </b-tab>
           </b-tabs>         
      </b-card>
       
        <b-modal
            id="modalResposible"
            ref="modal"
            title-tag="h6"
            size="lg"
            title="Registro do Responsável"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            @ok="saveResposible"
        >
            <b-row>
                <b-col md="3" sm="12">
                    <b-form-group
                        label="CPF:"
                        label-for="users_responsible-cpf_responsible"
                    >
                        <b-form-input
                            v-mask="['###.###.###-##']"
                            id="users_responsible-cpf_responsible"
                            type="text"
                            :state="cpfState"
                            ref="focusThis"
                            v-model="users_responsible.cpf_responsible"
                            size="sm"
                            placeholder="CPF..."
                        />
                        <b-form-invalid-feedback id="input-live-cpf">
                           CPF Obrigatório
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col md="9" sm="12">
                    <b-form-group
                        label="Nome:"
                        label-for="users_responsible-name_responsible"
                    >
                        <b-form-input
                            id="users_responsible-name_responsible"
                            type="text"
                            :state="nameState"
                            v-model="users_responsible.name_responsible"
                            size="sm"
                            placeholder="Nome..."
                        />
                          <b-form-invalid-feedback id="input-live-name">
                           Nome Obrigatório
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                 <b-col md="4" sm="6">
                        <b-form-group
                                label="Tipo de Responsável"
                                label-for="users_responsible-type_responsable_id"
                            >
                                <b-form-select
                                    id="users_responsible-type_responsable_id"
                                    size="sm"
                                    :options="typeresponsables"
                                    v-model=" users_responsible.type_responsable_id"
                                />
                            </b-form-group>
                    </b-col> 
                <b-col md="4" sm="12">
                    <b-form-group
                        label="RG:"
                        label-for="users_responsible-rg_responsible"
                    >
                        <b-form-input
                            id="users_responsible-rg_responsible"
                            type="text"
                            :state="rgState"
                            v-model="users_responsible.rg_responsible"
                            size="sm"
                            placeholder="RG..."
                        />
                          <b-form-invalid-feedback id="input-live-rg">
                           RG Obrigatório
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="6">
                <b-form-group
                        label="Estado Civil"
                        label-for="users_responsible-martial_status_id"
                    >
                        <b-form-select
                            id="users_responsible-martial_status_id"
                            size="sm"
                            :options="marital_status"
                            v-model=" users_responsible.martial_status_id"
                        />
                    </b-form-group>
                </b-col>
                   
            </b-row>
            <b-row>
      
                     <b-col md="4" sm="12">
                    <b-form-group
                        label="Profissão:"
                        label-for="users_responsible-profession_responsible"
                    >
                        <b-form-input
                            id="users_responsible-profession_responsible"
                            type="text"
                            v-model="users_responsible.profession_responsible"
                            size="sm"
                            placeholder="Profissão..."
                        />
                    </b-form-group>
                </b-col>
                   <b-col md="3" sm="12">
                    <b-form-group
                        label="Nacionalidade:"
                        label-for="users_responsible-nationality_responsible"
                    >
                        <b-form-input
                            id="users_responsible-nationality_responsible"
                            type="text"
                            v-model="users_responsible.nationality_responsible"
                            size="sm"
                            placeholder="Nacionalidade..."
                        />
                    </b-form-group>
                </b-col>
                  <b-col md="9" sm="12">
                    <b-form-group
                        label="Endereço:"
                        label-for="users_responsible-address_responsible"
                    >
                        <b-form-input
                            id="users_responsible-address_responsible"
                            type="text"
                            v-model="users_responsible.address_responsible"
                            size="sm"
                            placeholder="Endereço..."
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                 <b-col md="6" sm="12">
                    <b-form-group
                        label="E-mail:"
                        label-for="users_responsible-mail_responsible"
                    >
                        <b-form-input
                            id="users_responsible-mail_responsible"
                            type="text"
                            :state="mailState"
                            v-model="users_responsible.mail_responsible"
                            size="sm"
                            placeholder="E-mail..."
                        />
                          <b-form-invalid-feedback id="input-live-mail">
                           E-mail Obrigatório
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                   <b-col md="3" sm="12">
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="users_responsible.to_sign_reponsable"
                                true-value="1"
                                false-value="0"
                                type="checkbox"
                                id="users_responsible-to_sign_reponsable"
                            />
                            <label
                                class="form-check-label"
                                for="users_responsible-to_sign_reponsable"
                            >
                                Resp. por Assinar?
                            </label>
                        </div>
                    </div>
                </b-col>
                  <b-col md="3" sm="12">
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="users_responsible.active_responsable"
                                true-value="1"
                                false-value="0"
                                type="checkbox"
                                id="users_responsible-active_responsable"
                            />
                            <label
                                class="form-check-label"
                                for="users_responsible-active_responsable"
                            >
                                Ativo?
                            </label>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal
            id="modalResposibleFileSearch"
            title-tag="h6"
            size="xl"
            centered
            ok-only
            title="Documento(s)"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
            <b-table
                class="table-responsive"
                hover
                head-variant="light"
                :items="company_employee_files"
                :fields="fieldsFile"
  
            >
                <template v-slot:cell(actions)="data">
                    <b-button
                        title="Visualizar"
                        size="sm"
                        variant="light"
                        v-if="data.item.storage_file_id > 0"
                        @click="
                            ;[
                                loadCompanyEmployeeFile(data.item),
                                openModalEmployeeFilePDF()
                            ]
                        "
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-file-pdf-o"></i>
                        Visualizar
                    </b-button>
                </template>
            </b-table>
        </b-modal>
        <b-modal
             v-model="modalFileShow"
            id="modalResposibleFile"
            title-tag="h6"
            centered
            size="xl"
            title="Registro Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
             @ok="loadUserFiles"
    
        >
        <RegisterUserDoc/>
             <!--b-row>
                <b-col md="7" sm="12">
                    <b-form-group
                        label="Tipo de Documento:"
                        label-for="users_file-type_document_unique_id"
                    >
                        <b-form-select
                            id="users_file-type_document_unique_id"
                            size="sm"
                            :options="type_document_uniques"
                            @input="[file_doc_show = false,loadTypeDocument()]"
                            :disabled="type_doc_show"
                            v-model="
                                users_file.type_document_unique_id
                            "
                        />
                    </b-form-group>
                </b-col>
                <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        ref="file-input"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <b-row  v-show="type_document_unique.has_date">
                  <b-col md="3" sm="12">
                    <b-form-group
                        label="Data Validade:"
                        label-for="users_file-validade_date"
                    >
                        <b-form-input
                            id="users_file-validade_date"
                            type="date"
                            v-model="users_file.validade_date"
                            size="sm"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template!-->
        </b-modal>
        <b-modal
            id="modalResposibleFilePDF"
            ref="modal"
            title-tag="h6"
            centered
            size="lg"
            ok-only
            title="Visualização do Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
            <div>
                <pdf :src="users_file.url_file"></pdf>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import { mask } from 'vue-the-mask'
import RegisterUserDoc from './RegisterUserDoc.vue'
import pdf from 'vue-pdf'

export default {
    name: 'CompanyRegister',
    directives: { mask },
    components: { PageTitle, pdf,RegisterUserDoc },
    data: function() {
        return {
            mode: 'insert',
            search: '',
            users_complement:{},
            users_address:{},
            users_tax:{},
            users_bank:{},
            users_responsible: {},
            users_responsibles: [],
            users_files:[],
            users_file:{},
            regime_iss:[],
            company_sizes:[],
            company: {},
             companys: [],
             street_types:[],
             district_types:[],
             countrys:[],
             states:[],
             cities: [],
             banks: [],
             typeresponsables:[],
             typeaccounts: [],
             paymentmethods:[],
             marital_status:[],
            company_employee: {},
            company_employees: [],
            company_employee_file: {},
            company_employee_files: [],
             isBusyEmployee: false,
            type_document_uniques: [],
            status_employee_id: null,
            file: null,
            type_doc_show: false,
            file_doc_show: true,
            file_upload_show: false,
            upload_show: false,
            allow_document_unique: false,
            id_file_type_document: null,
            type_document_unique:{},
            perPage: 5,
            currentPage: 1,
            showTable: false,
            modalFileShow: false,
            headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
            question_yes_no:[
                {text: 'Não',value: '0'},
                {text: 'Sim',value: '1'}
            ],
             status_employee_searh: [
                        { text: 'TODOS', value: null },
                         { text: 'ABERTO', value: 0 },
                        { text: 'EM ANDAMENTO', value: 1 },
                        { text: 'REJEITADO', value: 2 },
                        { text: 'CONCLUÍDA', value: 3 },
                        { text: 'CANCELADO', value: 4 }

                    ],
            fieldsResponsible: [
                {
                    key: 'name_responsible',
                    label: 'Nome Responsável',
                    sortable: false
                },
                {
                    key: 'cpf_responsible',
                    label: 'CPF',
                    sortable: false
                },
                {
                    key: 'rg_responsible',
                    label: 'RG',
                    sortable: false
                },
                 {
                    key: 'profession_responsible',
                    label: 'Profissão',
                    sortable: false
                },
                {
                    key: 'nationality_responsible',
                    label: 'Nacionalidade',
                    sortable: false
                },
                {
                    key: 'address_responsible',
                    label: 'Endereço',
                    sortable: false
                },
                 {
                    key: 'name_marital_status',
                    label: 'Estado Civil',
                    sortable: false
                },
                {
                    key: 'mail_responsible',
                    label: 'E-mail',
                    sortable: false
                },
                 {
                    key: 'name_type_responsable',
                    label: 'Tipo de Responsável',
                    sortable: false
                },
                {
                    key: 'to_sign_reponsable',
                    label: 'Resp. por Assinar?',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                                {
                    key: 'active_responsable',
                    label: 'Ativo?',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                { key: 'actions', label: 'Ações', variant: 'light' }
            ],
            fieldsFile: [
                {
                    key: 'name_type_document_unique',
                    label: 'Documento',
                    sortable: true
                },
                {
                    key: 'name_file',
                    label: 'Arquivo',
                    sortable: false
                },
                {
                    key: 'status_type_document_file',
                    label: 'Status',
                    sortable: false,
                    formatter: value => this.status_file(value)
                },
                {
                    key: 'justify_comments',
                    label: 'Justificativa',
                    sortable: false
                },
                { key: 'actions', label: 'Ações', variant: 'light' }
            ]
        }
    },
    computed: {
        cpfState() {
            return this.users_responsible.cpf_responsible ? true : false
        },
        mailState() {
            return this.users_responsible.mail_responsible ? true : false
        },
        rgState() {
            return this.users_responsible.rg_responsible ? true : false
        },
        nameState() {
            return this.users_responsible.name_responsible ? true : false
        },
        compan() {
            return this.$store.state.compan
        },
        icon() {
            return this.showTable ? 'fa-retweet' : 'fa-search'
        },
        user() {
            return this.$store.state.user
        }
    },
    methods: {
        showMsgBoxRemoveResponsable(item) {
                this.boxCancel = ''
                this.$bvModal
                .msgBoxConfirm('Deseja remover o registro ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                      await  this.removeResponsable(item)
        
                    }
                })
            },
         toggleBusy() {
            this.isBusyEmployee = true
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status_description === 'Concluida') return 'table-success'
            if (item.status_description === 'cancelado') return 'table-danger'
            if (item.status_description === 'docRegeitado') return 'table-warning'
         },
        rowClassDocument(item, type) {
            if (!item || type !== 'row') return
            if (item.status_type_document_file === 1) return 'table-success'
            if (item.status_type_document_file === 2) return 'table-warning'
         },
        status_file(value) {
            if (value === 0) {
                return 'PENDENTE'
            } else if (value === 1) {
                return 'AUTORIZADO'
            } else if (value === 2) {
                return 'REJEITADO'
            } else if (value === 3) {
                return 'EM ANALISE'
            }
        },
           status_employee(value) {
            if (value === 0) {
                return 'ABERTO'
            } else if (value === 1) {
                return 'EM ANDAMENTO'
            } else if (value === 2) {
                return 'REJEITADO'
            } else if (value === 3) {
                return 'CONCLUÍDA'
            }else if (value === 4) {
                return 'CANCELADO'
            }
        },
         showMsgBoxAllow() {
            this.boxCancel = ''
            this.$bvModal
                .msgBoxConfirm('Deseja solicitar aprovação ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                            this.allow_document_unique = true
                        await this.allowFiles()
                    }
                })
        },
         showMsgBoxAllowCompl() {
            this.boxCancel = ''
            this.$bvModal
                .msgBoxConfirm('Deseja solicitar aprovação ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                            this.allow_document_unique = true
                        await this.allowComplementFiles()
                    }
                })
        },
      async  loadUserFiles() {
            const url = `${baseApiUrl}/usersfiles/${this.user.id}/user`
         await   axios(url).then(res => {
                this.users_files = res.data
            })
            .finally(()=>{
                this.upload_show = false;
            })
        },
        reset() {
            this.mode = 'insert'
        },
      
        async saveFile() {

            if(this.users_complement.return_preview_code == 0){
                this.users_file.file_complement = 1
            }

            this.users_file.user_id_file = this.user.id
            const method = this.users_file.id ? 'put' : 'post'
            const id = this.users_file.id
                ? `/${this.users_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/usersfiles${id}`,
                this.users_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    //this.loadUserFiles()
                })
                .finally(async () => {
                    await this.getLastFileTypeDocument()
                })
                .catch(showError)
        },
        async returnCodeFile() {
            const method = 'put'
            const id = this.company_employee_file.id
                ? `/${this.company_employee_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companyemployeefilescodereturn${id}`,
                this.company_employee_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadUserFiles()
                })
                .catch(showError)
        },
        remove(item) {
            const id = item.id
            axios
                .delete(`${baseApiUrl}/companyemployees/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyEmployees()
                })
                .catch(showError)
        },
        alterModo() {
                    this.mode = 'save'
                    this.company = {}
        },
        loadCompanyEmployee(item) {
            this.company_employee = { ...item }
        },
        loadCompanyEmployeeFile(item) {
            this.users_file = { ...item }
        },
       async  loadTypeDocuments() {
            const url = `${baseApiUrl}/typedocumentsuserfiles/${this.user.id}`
         await   axios.get(url).then(res => {
                this.type_document_uniques = res.data.map(type_document => {
                    return {
                        value: type_document.id,
                        text: type_document.name_type_document_unique
                    }
                })
            })
        },
        openModalResponsilble() {
            this.$bvModal.show('modalResposible')
        },
        editModalResponsilble(item) {
            this.users_responsible = {...item}
            this.$bvModal.show('modalResposible')
        },
        async   openModalResposibleFile() {
         await   this.loadTypeDocuments()
          this.users_file = {}
          this.type_document_unique = {}
           this.type_doc_show =false
           this.file_upload_show = false
            this.$bvModal.show('modalResposibleFile')
           
        },
        async  removeResponsable(item) {
            axios
                .delete(`${baseApiUrl}/usersresponsibles/${item.id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadResposibles()
                })
                .catch(showError)
        },
        async openModalEmployeeFileSearch() {
         await   this.loadUserFiles()
            this.$bvModal.show('modalResposibleFileSearch')
        },
        openModalEmployeeFilePDF() {
            this.$bvModal.show('modalResposibleFilePDF')
        },
         closeModalEmployeeFilePDF() {
            this.$bvModal.hide('modalResposibleFilePDF')
        },
          closeModalResposibleFile() {
            this.$bvModal.hide('modalResposibleFile')
        },
        clearFiles() {
         this.$refs['file-input'].reset()
        },
        async saveImagem() {
          
            if(this.type_document_unique.has_date && !this.users_file.validade_date){      
                  this.$toasted.global.defaultWarning({
                                msg: "Data de validade obrigatório para este documento....",
                                });
                   this.clearFiles()
            }else{
                 this.file_upload_show = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('user_id_unique', this.user.id)
            formData.append('type_document_unique_id', this.users_file.type_document_unique_id)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( async () => {
                    this.$toasted.global.defaultSuccess()
                     await  this.getLastStorageFile()
                    this.type_doc_show =true
                })
                .finally(async ()=>{
                    if(this.users_file.storage_file_id > 0){
                      await  this.saveFile()
                    }else{
                          this.$toasted.global.defaultWarning({
                                msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                            });
                    }
                })
                .catch(showError)
            }
           
        },
        async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesuniquebylast/?name_file=${this.file.name}&user_id_unique=${this.user.id}&type_document_unique_id=${this.users_file.type_document_unique_id}`
            await axios(url).then(
                res =>
                    (this.users_file = {
                        storage_file_id: res.data.id,
                        name_file: res.data.name_file,
                        path_file: res.data.path_file,
                        id: this.users_file.id,
                        validade_date: this.users_file.validade_date,
                        type_document_unique_id: this.users_file
                            .type_document_unique_id
                    })
            )
        },
        async getLastFileTypeDocument() {
            const url = `${baseApiUrl}/usersfilesbylast/?user_id_file=${this.user.id}&type_document_id_unique=${this.users_file.type_document_unique_id}`
            await axios(url).then(
                res =>
                    (
                        this.checkFileFtpById(res.data.id)
                    )
            )
        },
        async checkFileFtp(item) {
            this.company_employee_file = { ...item }
            
            if(item.storage_file_id > 0){
                  await axios['put'](
                `${baseApiUrl}/companyemployeefilesftp/${this.company_employee_file.id}`,
                this.company_employee_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                   
                 
                })
                .finally( () => {
                        this.loadUserFiles()
                      
                })
                .catch(showError)
            }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }
          
        },
        async checkFileFtpById(id) {

                  await axios['put'](
                `${baseApiUrl}/useresfilesftpbyid/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.modalFileShow = false
                })
                .finally( () => {
                        this.loadUserFiles()
                           
                })
                .catch(showError)
          
          
        },
        async  allowFiles(){
                const method = 'post'
                const id = this.user.id
                await axios[method](
                `${baseApiUrl}/executecontratctusers/${id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                })
                .finally( () => {
                          this.allow_document_unique = false
                      
                })
                .catch(showError)

        },
        async  allowComplementFiles(){
                const method = 'post'
                const id = this.user.id
                await axios[method](
                `${baseApiUrl}/executecontractcomplementusers/${id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                })
                .finally( () => {
                          this.allow_document_unique = false
                      
                })
                .catch(showError)

        },
        async loadStreetTypes() {
            const url = `${baseApiUrl}/streettypes`
            await axios
                .get(url)
                .then(res => {
                    this.street_types = res.data.map(street_types => {
                        return {
                            value: street_types.CODIGO,
                            text:
                                street_types.DESCRICAO 
                        }
                    })
                })
        },
        async loadDistrictTypes() {
            const url = `${baseApiUrl}/districttypes`
            await axios
                .get(url)
                .then(res => {
                    this.district_types = res.data.map(street_types => {
                        return {
                            value: street_types.CODIGO,
                            text:
                                street_types.DESCRICAO 
                        }
                    })
                })
        },
        async loadCountrys() {
            const url = `${baseApiUrl}/countrys`
            await axios
                .get(url)
                .then(res => {
                    this.countrys = res.data.map(street_types => {
                        return {
                            value: street_types.IDPAIS,
                            text:
                                street_types.DESCRICAO 
                        }
                    })
                })
        },
        async loadStates() {
            const url = `${baseApiUrl}/states`
            await axios
                .get(url)
                .then(res => {
                    this.states = res.data.map(street_types => {
                        return {
                            value: street_types.CODIGO,
                            text:
                                street_types.NOME 
                        }
                    })
                })
        },
        async loadBanks() {
            const url = `${baseApiUrl}/banks`
            await axios
                .get(url)
                .then(res => {
                    this.banks = res.data.map(bank => {
                        return {
                            value: bank.NUMBANCO,
                            text:
                                bank.NOME 
                        }
                    })
                })
        },
        async loadCities() {
            const url = `${baseApiUrl}/cities/?uf=${this.users_address.state_code}`
            await axios
                .get(url)
                .then(res => {
                    this.cities = res.data.map(street_types => {
                        return {
                            value: street_types.CODMUNICIPIO,
                            text:
                                street_types.NOME 
                        }
                    })
                })
        },
        loadRegimeISS() {
            const url = `${baseApiUrl}/regimeiss`
             axios
                .get(url)
                .then(res => {
                    this.regime_iss = res.data.map(regime_iss => {
                        return {
                            value: regime_iss.id,
                            text:
                                regime_iss.name_regime_iss 
                        }
                    })
                })
        },
        loadMarittalStatus() {
            const url = `${baseApiUrl}/maritalstatus`
             axios
                .get(url)
                .then(res => {
                    this.marital_status = res.data.map(marital_status => {
                        return {
                            value: marital_status.id,
                            text:
                                marital_status.name_marital_status 
                        }
                    })
                })
        },
          loadTypeAccounts() {
            const url = `${baseApiUrl}/typeaccounts`
             axios
                .get(url)
                .then(res => {
                    this.typeaccounts = res.data.map(typeaccount => {
                        return {
                            value: typeaccount.id,
                            text:
                                typeaccount.type_account_name 
                        }
                    })
                })
        },
        loadCompanySize() {
            const url = `${baseApiUrl}/companysizes`
             axios
                .get(url)
                .then(res => {
                    this.company_sizes = res.data.map(street_types => {
                        return {
                            value: street_types.id,
                            text:
                                street_types.name_company_size 
                        }
                    })
                })
        },
        loadPaymentMethods() {
            const url = `${baseApiUrl}/paymentmethods`
             axios
                .get(url)
                .then(res => {
                    this.paymentmethods = res.data.map(paymentmethod => {
                        return {
                            value: paymentmethod.id,
                            text:
                                paymentmethod.name_payment_method 
                        }
                    })
                })
        },
        async saveComplement() {

            if(this.users_responsibles.length === 0){
                           this.$toasted.global.defaultWarning({
                                msg: "Deve ser informado pelo menos um responável....",
                                });
            }else{

                 this.users_complement.user_id_complement = this.user.id

                    const method = this.users_complement.id ? 'put' : 'post'

                    const id = this.users_complement.id
                        ? `/${this.users_complement.id}`
                        : ''
                await   axios[method](
                        `${baseApiUrl}/userscomplements${id}`,
                        this.users_complement
                    )
                    .then(async () => {
                        if(!this.users_address.zipcode >0){
                                this.$toasted.global.defaultWarning({
                                        msg: "Falta preencher os dados de endereço....",
                                        });
                        }
                            this.saveAddress()
                            await this.loadComplement()
                            this.upload_show = false;
                        })
                        .catch(showError)
                    }
        },
        async saveAddress() {

         const street_type_name = this.street_types.filter(item => item.value == this.users_address.street_type);    
         const country_name = this.countrys.filter(item => item.value == this.users_address.country_code);    
         const district_type_name = this.district_types.filter(item => item.value == this.users_address.district_type);    
         const state_name = this.states.filter(item => item.value == this.users_address.state_code);    
         const city_name = this.cities.filter(item => item.value == this.users_address.city_code);    

          this.users_address.user_id_address = this.user.id

          this.users_address.street_type_name = street_type_name[0].text
          this.users_address.country_name = country_name[0].text
          this.users_address.district_type_name = district_type_name[0].text
          this.users_address.state_name = state_name[0].text
          this.users_address.city_name = city_name[0].text

            const method = this.users_address.id ? 'put' : 'post'

            const id = this.users_address.id
                ? `/${this.users_address.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/usersaddress${id}`,
                this.users_address
            )
               .then(async () => {

                     if(!this.users_tax.iss >0){
                           this.$toasted.global.defaultWarning({
                                msg: "Falta preencher os dados para contrato....",
                                });
                   }
                    this.saveTax()
                    await this.loadAddress()
                })
                .catch(showError)
        },
        async saveTax() {

            this.users_tax.user_id_tax = this.user.id

            const method = this.users_tax.id ? 'put' : 'post'

            const id = this.users_tax.id
                ? `/${this.users_tax.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/userstaxs${id}`,
                this.users_tax
            )
               .then(async () => {

                    if(!this.users_bank.bank_code >0){
                        this.$toasted.global.defaultWarning({
                        msg: "Falta preencher os dados bancários....",
                        });
                    }
                    this.saveBank()
                    await this.loadTax()
                })
                .catch(showError)
        },
        async saveBank() {

            const bank_name = this.banks.filter(item => item.value == this.users_bank.bank_code);

             this.users_bank.bank_name = bank_name[0].text

            this.users_bank.user_id_bank = this.user.id

            const method = this.users_bank.id ? 'put' : 'post'

            const id = this.users_bank.id
                ? `/${this.users_bank.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/usersbanks${id}`,
                this.users_bank
            )
               .then(async () => {
                    this.$toasted.global.defaultSuccess()
                    await this.loadBank()
                    this. mode = 'insert'
                })
                .catch(showError)
        },
        async saveResposible() {

            this.users_responsible.user_id_responsible = this.user.id

            const method = this.users_responsible.id ? 'put' : 'post'

            const id = this.users_responsible.id
                ? `/${this.users_responsible.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/usersresponsibles${id}`,
                this.users_responsible
            )
               .then(() => {
                    this.$toasted.global.defaultSuccess()
                           this.users_responsible = {}
                })
                .finally( () => {
                        this.loadResposibles()
                })
                .catch(showError)
        },
        async loadComplement() {
            this.upload_show = true;
            const url = `${baseApiUrl}/userscomplements/${this.user.id}`
            await axios
                .get(url)
                .then(res => {
                    this.users_complement = {...res.data}})
        },
        async loadAddress() {
            const url = `${baseApiUrl}/usersaddress/${this.user.id}`
            await axios
                .get(url)
                .then(res => {
                    this.users_address = {...res.data}})
        },
        async loadTax() {
            const url = `${baseApiUrl}/userstaxs/${this.user.id}`
            await axios
                .get(url)
                .then(res => {
                    this.users_tax = {...res.data}})
        },
        async loadBank() {
            const url = `${baseApiUrl}/usersbanks/${this.user.id}`
            await axios
                .get(url)
                .then(res => {
                    this.users_bank = {...res.data}})
        },
         loadResposibles() {
            const url = `${baseApiUrl}/usersresponsiblesidbase/${this.user.id}`
                axios(url).then(res => {
                this.users_responsibles = res.data
            })
        },
        loadTypeDocument() {
            const url = `${baseApiUrl}/typedocumentuniques/${this.users_file.type_document_unique_id}`
            axios
                .get(url)
                .then(res => {
                    this.type_document_unique = {...res.data}})
        },
         loadTypeResponsables() {
            const url = `${baseApiUrl}/typeresponsables`
             axios
                .get(url)
                .then(res => {
                    this.typeresponsables = res.data.map(type_responsable => {
                        return {
                            value: type_responsable.id,
                            text:
                                type_responsable.name_type_responsable 
                        }
                    })
                })
        },

    },
    
    async mounted() {
        await this.loadComplement()
        await this.loadStreetTypes()    
        await this.loadDistrictTypes()
        await this.loadCountrys()
        await this.loadStates()
        await this.loadBanks()
        await this.loadAddress()
        await this.loadTax()
        await this.loadBank()
        await this.loadUserFiles()
                 this.loadResposibles()
                  this.loadRegimeISS()  
                  this.loadCompanySize()
                  this.loadTypeAccounts()
                  this.loadPaymentMethods()
                  this.loadMarittalStatus()
                  this.loadTypeResponsables()

    }
}
</script>

<style>
.company_employee-search {
    font-size: 0.75rem;
}

.company_employee-search button {
    font-size: 0.75rem;
}

.company_employee-search select {
    font-size: 0.75rem;
}

.company_employee-search input {
    font-size: 0.75rem;
}

#modalResposible {
    font-size: 0.75rem;
}

#modalResposible button {
    font-size: 0.75rem;
}

#modalResposible input {
    font-size: 0.75rem;
}

#modalResposible select {
    font-size: 0.75rem;
}

#modalResposibleFile {
    font-size: 0.75rem;
}

#modalResposibleFile button {
    font-size: 0.75rem;
}

#modalResposibleFile input {
    font-size: 0.75rem;
}

#modalResposibleFile select {
    font-size: 0.75rem;
}

#modalResposibleFileSearch {
    font-size: 0.75rem;
}

#modalResposibleFileSearch button {
    font-size: 0.75rem;
}

#modalResposibleFileSearch table {
    font-size: 0.75rem;
}

#modalResposibleFilePDF {
    font-size: 0.75rem;
}

#modalResposibleFilePDF button {
    font-size: 0.75rem;
}

@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
