<template>
    <div class="company_document_monthly-search">
        <PageTitle
            icon="fa fa-clone"
            main="Registro"
            sub="Documentação Mensal"
        />
        <b-row>
              <b-col md="6" sm="6">
                   <b-form-group
                        label="Selecione o Contrato:"
                        label-for="company_search"
                    >
                        <b-form-select
                            id="company_search"
                            size="sm"
                            :options="companys"
                            @input="loadCompanyMonthly"
                            v-model=" company_id"
                        />
                    </b-form-group>
              </b-col>
              <b-col md="2" sm="2">
                    <b-button
                        title="Atualizar Lista"
                        size="md"
                        variant="dark"
                        @click="loadCompanyMonthly()"
                        class="mr-2 mt-4 mb-2"
                    >
                        <i class="fa fa-refresh"></i>
                        Atualizar
                </b-button>
              </b-col>
        </b-row>
        <b-row>
               <b-col md="12" sm="12">
                <transition name="slide" type="animation" appear>
                    <b-table
                         :busy="isBusyMonthly"
                        class="table-responsive"
                        hover
                        head-variant="ligth"
                        :items="company_document_monthlys"
                        :fields="fieldMonthly"
                        :tbody-tr-class="rowClass"
                    >
                    <template v-slot:table-busy>
                        <div class="text-center text-dark my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Carregando...</strong>
                        </div>
                    </template>
                        <template v-slot:cell(actions)="data">
                             <b-button
                                title="Iníciar Período"
                                size="sm"
                                variant="light"
                                 :disabled="request_accept"
                                 v-if="data.item.parent_doc_monthly_id == 0 && data.item.staus_id_monthly ==1"
                                @click="returnCodeFolderMonthly(data.item)"
                                class="mr-2 mt-2"
                            >
                                <div  class="d-flex justify-content-center ">
                                <b-spinner v-show="request_accept" label="Enviando..."></b-spinner>
                                </div>
                                <div v-show="!request_accept">
                                    <i  v-show="!request_accept" class="fa fa-download"></i>
                                         Iníciar Período
                                </div>
                            </b-button>
                            <b-button
                                title="Solicitar aprovação Documento(s)"
                                size="sm"
                                variant="light"
                                 v-if="data.item.staus_id_monthly ==1 && data.item.parent_doc_monthly_id > 0 && data.item.return_preview_code == 0"
                                @click="
                                    ;[
                                        showMsgBoxAllow(data.item)
                                    ]
                                "
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-exchange"></i>
                                Solicitar Aprovação
                            </b-button>
                            <b-button
                                title="Visualizar Documento(s)"
                                size="sm"
                                variant="light"
                                v-if="data.item.parent_doc_monthly_id > 0"
                                @click="
                                    ;[
                                        loadCompanyEmployee(data.item),
                                        loadCompanyEmployeeFilesModal()
                                    ]
                                "
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-drivers-license-o"></i>
                                Visualizar Documento(s)
                            </b-button>
                            <b-button
                                title="Adicionar Documento"
                                size="sm"
                                variant="light"
                                 v-if="data.item.staus_id_monthly !=3 && data.item.staus_id_monthly !=4 && data.item.parent_doc_monthly_id > 0 && data.item.return_preview_code == 0"
                                @click="
                                    ;[
                                        loadCompanyEmployee(data.item),
                                        openModalEmployeeFile(),
                                        file_doc_show = true,
                                        modalFileShow = true,
                                        file_upload_show = false,
                                        (company_document_monthly_file = {})
                                    ]
                                "
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-plus"></i>
                                Adicionar Documento
                            </b-button>
                             <!--b-button
                                title="Documento Complementar"
                                size="sm"
                                variant="light"
                                 v-if="data.item.return_preview_code > 0"
                                @click="
                                    ;[
                                        loadCompanyEmployee(data.item),
                                        openModalEmployeeFileExtends(),
                                        file_doc_show = true,
                                        modalFileShow = true,
                                        file_upload_show = false,
                                        (company_document_monthly_file = {})
                                    ]
                                "
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-plus"></i>
                                Documento Complementar
                            </b-button-->
                        </template>
                    </b-table>
                 
                </transition>
                <transition name="slide" type="animation" appear>
                </transition>
            </b-col>
        </b-row>
        <b-modal
            id="modalMonthlyFileSearch"
            title-tag="h6"
            size="xl"
            centered
            ok-only
            title="Documento(s)"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
            <b-table
                class="table-responsive"
                hover
                head-variant="light"
                :items="company_document_monthly_files"
                :fields="fieldsFile"
  
            >
                <template v-slot:cell(actions)="data">
                    <b-button
                         title="Editar"
                        size="sm"
                        variant="light"
                         v-if="company_document_monthly.return_preview_code == 0"
                          @click="
                            ;[
                                loadCompanyEmployeeFile(data.item),
                                  file_upload_show = false,
                                openModalEmployeeFileEdit()
                            ]"   
                          class="mr-2 mt-2"
                    >
                     <i class="fa fa-edit"></i>
                        Editar
                    </b-button>
                    <b-button
                        title="Visualizar"
                        size="sm"
                        variant="light"
                        v-if="data.item.storage_file_id > 0"
                        @click="
                            ;[
                                loadCompanyEmployeeFile(data.item),
                                openModalEmployeeFilePDF()
                            ]
                        "
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-file-pdf-o"></i>
                        Visualizar
                    </b-button>
                </template>
            </b-table>
        </b-modal>
        <b-modal
             v-model="modalFileShow"
            id="modalEmployeeFile"
            title-tag="h6"
            centered
            ok-only
            size="xl"
            title=""
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
    
        >
        <RegisterMonthlyDoc :company_monthly="company_document_monthly"/>
            <!--b-row>
                <b-col md="7" sm="12">
                    <b-form-group
                        label="Tipo de Documento:"
                        label-for="company_document_monthly_file-type_documento_monthly_id"
                    >
                        <b-form-select
                            id="company_document_monthly_file-type_documento_monthly_id"
                            size="sm"
                            :options="type_documents"
                            @input="file_doc_show = false"
                            :disabled="type_doc_show"
                            v-model="
                                company_document_monthly_file.type_documento_monthly_id
                            "
                        />
                    </b-form-group>
                </b-col>
                <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template-->
        </b-modal>
        <b-modal
            id="modalMonthlyFilePDF"
            ref="modal"
            title-tag="h6"
            centered
            size="lg"
            ok-only
            title="Visualização do Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
            <div>
                <pdf :src="company_document_monthly_file.url_file"></pdf>
            </div>
        </b-modal>
         <b-modal
             v-model="modalFileShowEdit"
            id="modalFileEdit"
            title-tag="h6"
            centered
            size="lg"
            title="Editar Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
    
        >
            <b-row>
                <b-col md="12" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShowEdit=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import RegisterMonthlyDoc from './RegisterMonthlyDoc.vue'
import pdf from 'vue-pdf'
var moment = require('moment-timezone')

export default {
    name: 'CompanyDocumentMonthly',
    components: { PageTitle, pdf, RegisterMonthlyDoc },
    data: function() {
        return {
            mode: 'insert',
            search: '',
            company: {},
             companys: [],
            company_document_monthly: {},
            company_document_monthlys: [],
            company_document_monthly_file: {},
            company_document_monthly_files: [],
             isBusyMonthly: false,
            type_documents: [],
            status_monthly_id: null,
            file: null,
            type_doc_show: false,
            file_doc_show: true,
            file_upload_show: false,
            id_file_type_document: null,
            request_accept:false,
            company_id:null,
            search_id: null,
            perPage: 5,
            currentPage: 1,
            showTable: false,
            modalFileShow: false,
            modalFileShowEdit:false,
            headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
             status_employee_searh: [
                        { text: 'TODOS', value: null },
                         { text: 'ABERTO', value: 0 },
                        { text: 'EM ANDAMENTO', value: 1 },
                        { text: 'REJEITADO', value: 2 },
                        { text: 'CONCLUÍDA', value: 3 },
                        { text: 'CANCELADO', value: 4 }

                    ],
            fieldMonthly:[
                {
                    key: 'description_monthly',
                    label: 'Período entre',
                    sortable: false
                },
                {
                    key: 'status_monthly',
                    label: 'Status',
                    sortable: false
                },
                { key: 'actions', label: 'Ações', variant: 'light' }
            ],
            fieldsFile: [
                {
                    key: 'name_type_document_monthly',
                    label: 'Documento',
                    sortable: true
                },
                {
                    key: 'name_file',
                    label: 'Arquivo',
                    sortable: false
                },
                {
                    key: 'status_type_document_file',
                    label: 'Status',
                    sortable: false,
                    formatter: value => this.status_file(value)
                },
                {
                    key: 'justify_comments',
                    label: 'Justificativa',
                    sortable: false
                },
                { key: 'actions', label: 'Ações', variant: 'light' }
            ]
        }
    },
    computed: {
        compan() {
            return this.$store.state.compan
        },
        icon() {
            return this.showTable ? 'fa-retweet' : 'fa-search'
        },
        rows() {
            return this.company_document_monthlys.length
        },
        user() {
            return this.$store.state.user
        }
    },
    methods: {
        toggleBusyMonthly() {
            this.isBusyMonthly = true
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.staus_id_monthly === 3) return 'table-success'
            if (item.staus_id_monthly === 4) return 'table-danger'
            if (item.staus_id_monthly === 2) return 'table-warning'
         },
        rowClassDocument(item, type) {
            if (!item || type !== 'row') return
            if (item.status_type_document_file === 1) return 'table-success'
            if (item.status_type_document_file === 2) return 'table-warning'
         },
        status_file(value) {
            if (value === 0) {
                return 'PENDENTE'
            } else if (value === 1) {
                return 'AUTORIZADO'
            } else if (value === 2) {
                return 'REJEITADO'
            } else if (value === 3) {
                return 'EM ANALISE'
            }  else if (value === 4) {
                return 'CANCELADO'
            }
        },
         showMsgBoxAllow(item) {
            this.boxCancel = ''
            this.$bvModal
                .msgBoxConfirm('Deseja solicitar aprovação ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                        this.toggleBusyMonthly()
                        await this.allowFiles(item)
                    }
                })
        },
        async loadCompanyMonthly() {
            const url = `${baseApiUrl}/companydocumentmonthly/${this.company_id}/company`
            await axios(url)
               .then(res => {
                    this.company_document_monthlys = res.data
                })

        },
      async  loadCompanyEmployeeFiles() {
            const url = `${baseApiUrl}/companydocumentmonthlyfiles/${this.company_document_monthly.id}/company`
         await   axios(url).then(res => {
                this.company_document_monthly_files = res.data
            })
        },
          async  loadCompanyEmployeeFilesModal() {
            const url = `${baseApiUrl}/companydocumentmonthlyfiles/${this.company_document_monthly.id}/company`
         await   axios(url).then(res => {
                this.company_document_monthly_files = res.data
            })   
            .finally( () => {
                     this.openModalEmployeeFileSearch()
                })
        },
        reset() {
            this.loadCompanyMonthly()
            this.company_document_monthly = {}
        },
        async saveFile() {
            this.company_document_monthly_file.company_doc_monthly_id = this.company_document_monthly.id
            const method = this.company_document_monthly_file.id ? 'put' : 'post'
            const id = this.company_document_monthly_file.id
                ? `/${this.company_document_monthly_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companydocumentmonthlyfiles${id}`,
                this.company_document_monthly_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    //this.loadCompanyEmployeeFiles()
                })
                .finally(async () => {
                    await this.getLastFileTypeDocument()
                })
                .catch(showError)
        },
        async returnCodeFile() {
            const method = 'put'
            const id = this.company_document_monthly_file.id
                ? `/${this.company_document_monthly_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companyemployeefilescodereturn${id}`,
                this.company_document_monthly_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyEmployeeFiles()
                })
                .catch(showError)
        },
        
        remove(item) {
            const id = item.id
            axios
                .delete(`${baseApiUrl}/companyemployees/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyMonthly()
                })
                .catch(showError)
        },

        async loadcompany() {
            const url = `${baseApiUrl}/companys/${this.company_id}`
            await axios
                .get(url)
                .then(res => {
                    this.company = {
                        ...res.data,
                        start_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD'),
                        end_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD')
                    }
                })
                  .then(async () => {
                    await this.loadCompanyMonthly()
                })
        },
        loadCompanyEmployee(item) {
            this.company_document_monthly = { ...item }
        },
        loadCompanyEmployeeFile(item) {
            this.company_document_monthly_file = { ...item }
        },
       async  loadTypeDocuments() {
            const url = `${baseApiUrl}/typedocumentmonthlyfiles/${this.company_document_monthly.id}`
         await   axios.get(url).then(res => {
                this.type_documents = res.data.map(type_document => {
                    return {
                        value: type_document.id,
                        text: type_document.name_type_document_monthly
                    }
                })
            })
        },
        async  loadTypeDocumentStepOrders() {
            const url = `${baseApiUrl}/typedocumentmonthlyfilessteporder/${this.company_document_monthly.id}`
         await   axios.get(url).then(res => {
                this.type_documents = res.data.map(type_document => {
                    return {
                        value: type_document.id,
                        text: type_document.name_type_document_monthly
                    }
                })
            })
        },
        openModalEmployee() {
            this.$bvModal.show('modalEmployee')
        },
      async  openModalEmployeeFile() {
         await   this.loadTypeDocuments()
           this.type_doc_show =false
            this.$bvModal.show('modalEmployeeFile')
           
        },
        openModalEmployeeFileEdit(){
            this.file_doc_show = false
            this.$bvModal.show('modalFileEdit')
        },
        async   openModalEmployeeFileExtends() {
         await   this.loadTypeDocumentStepOrders()
           this.type_doc_show =false
            this.$bvModal.show('modalEmployeeFile')
           
        },
        async openModalEmployeeFileSearch() {
         await   this.loadCompanyEmployeeFiles()
            this.$bvModal.show('modalMonthlyFileSearch')
        },
        openModalEmployeeFilePDF() {
            this.$bvModal.show('modalMonthlyFilePDF')
        },
         closeModalEmployeeFilePDF() {
            this.$bvModal.hide('modalMonthlyFilePDF')
        },
        async saveImagem() {

            this.file_upload_show = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('company_doc_monthly_id_storage', this.company_document_monthly.id)
            formData.append('type_document_monthly_id_storage', this.company_document_monthly_file.type_documento_monthly_id)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( async () => {
                    this.$toasted.global.defaultSuccess()
                     await  this.getLastStorageFile()
                    this.type_doc_show =true
                })
                .finally(async ()=>{
                    if(this.company_document_monthly_file.storage_file_id > 0){
                      await  this.saveFile()
                    }else{
                          this.$toasted.global.defaultWarning({
                                msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                                });
                    }
                })
                .catch(showError)
        },
        async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesmonthlydbylast/?company_doc_monthly_id_storage=${this.company_document_monthly.id}&type_document_monthly_id_storage=${this.company_document_monthly_file.type_documento_monthly_id}`
            await axios(url).then(
                res =>
                    (this.company_document_monthly_file = {
                        storage_file_id: res.data.id,
                        name_file: res.data.name_file,
                        path_file: res.data.path_file,
                        id: this.company_document_monthly_file.id,
                        type_documento_monthly_id: this.company_document_monthly_file
                            .type_documento_monthly_id
                    })
            )
        },
        async getLastFileTypeDocument() {
            const url = `${baseApiUrl}/companydocumentmonthlyfilesbylast/?company_doc_monthly_id=${this.company_document_monthly_file.company_doc_monthly_id}&type_documento_monthly_id=${this.company_document_monthly_file.type_documento_monthly_id}`
            await axios(url).then(
                res =>
                    (
                        this.checkFileFtpById(res.data.id)
                    )
            )
        },
        async checkFileFtp(item) {
            this.company_document_monthly_file = { ...item }
            
            if(item.storage_file_id > 0){
                  await axios['put'](
                `${baseApiUrl}/companydocumentmonthlyfilesftp/${this.company_document_monthly_file.id}`,
                this.company_document_monthly_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                })
                .finally( () => {
                        this.loadCompanyEmployeeFiles()
                })
                .catch(showError)
            }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }
        },
        async checkFileFtpById(id) {

                  await axios['put'](
                `${baseApiUrl}/companydocumentmonthlyfilesftp/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.modalFileShow = false
                    this.modalFileShowEdit = false
                })
                .finally( () => {
                        this.loadCompanyEmployeeFiles()
                           
                })
                .catch(showError)
        },
     async returnCodeFolderMonthly(item) {

          this.request_accept = true

            const method = 'post'
            const id = this.company_id
                ? `/${this.company_id}`
                : ''

        this.company_document_monthly = {...item}

            await axios[method](
                `${baseApiUrl}/returncompanymonthly${id}`,
                this.company_document_monthly
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                      this.request_accept = false
                    this.loadCompanyMonthly()
                    this.company_document_monthly = {}
                })
                .catch(showError)
        },
    async   allowFiles(item){
                  const method = 'post'
            const id = item.id
                ? `/${item.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companydocumentmonthlyfilescodereturn${id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyMonthly()
                    this.loadTypeDocuments()
                })
                .catch(showError)
                this.isBusyMonthly = false
        },
    async loadCompanys() {
            const url = `${baseApiUrl}/companybycompanys/?email=${this.user.email}&register_number=${this.user.register_number}&user_id=${this.user.id}`
            await axios
                .get(url)
                .then(res => {
                    this.companys = res.data.map(company => {
                        return {
                            ...company,
                            value: company.id,
                            text:
                                company.name_build_company +
                                '-' +
                                company.contact_number_company
                        }
                    })
                })
     },

    },
    
    async mounted() {

        await this.loadCompanys()    

      
              
    }
}
</script>

<style>
.company_document_monthly-search {
    font-size: 0.75rem;
}

.company_document_monthly-search button {
    font-size: 0.75rem;
}

.company_document_monthly-search select {
    font-size: 0.75rem;
}

.company_document_monthly-search input {
    font-size: 0.75rem;
}

#modalEmployee {
    font-size: 0.75rem;
}

#modalEmployee button {
    font-size: 0.75rem;
}

#modalEmployee input {
    font-size: 0.75rem;
}

#modalEmployeeFile {
    font-size: 0.75rem;
}

#modalEmployeeFile button {
    font-size: 0.75rem;
}

#modalEmployeeFile input {
    font-size: 0.75rem;
}

#modalEmployeeFile select {
    font-size: 0.75rem;
}

#modalMonthlyFileSearch {
    font-size: 0.75rem;
}

#modalMonthlyFileSearch button {
    font-size: 0.75rem;
}

#modalMonthlyFileSearch table {
    font-size: 0.75rem;
}

#modalMonthlyFilePDF {
    font-size: 0.75rem;
}

#modalMonthlyFilePDF button {
    font-size: 0.75rem;
}

#modalFileEdit {
     font-size: 0.75rem;
}

#modalFileEdit button {
     font-size: 0.75rem;
}


@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
